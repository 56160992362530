import React from 'react';

const About = () => {
  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <h1>About Us</h1>
      <p>We are dedicated to providing the best services.</p>
    </div>
  );
};

export default About;
