import React from 'react';

const Contact = () => {
  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
      <h1>Contact Us</h1>
      <p>You can reach us at contact@example.com</p>
    </div>
  );
};

export default Contact;
