import React from 'react';
import { Container, Navbar, Nav, Button, Form, FormControl, Row, Col } from 'react-bootstrap';

const Home = () => {
  return (
    <>
      <header class="header">
  

    </header>

    <div class="container coming-soon-container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center">
                <img src="/img/coming-soon.jpg" alt="Coming Soon" class="img-fluid coming-soon-image mb-4"/>

                <h1 class="mb-3">We're Launching Soon</h1>
                <p class="mb-4">Stay tuned! Something amazing is coming your way.</p>
                
                <form id="signup-form" action="/signup" method="POST" class="mb-3">
                    <div class="input-group">
                        <input type="email" class="form-control" name="email" placeholder="Enter your email" required />
                        <button class="btn btn-primary" type="submit">Notify Me</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </>
  );
};

export default Home;
